<template>
  <div class="app-body">
    <bread-crumb></bread-crumb>
    <el-card class="el-main a-fs-14 projectadd">
      <div class="flex_start mgb24 pdb10 bordrb2">
        <div class="fnW600 font18">{{ detailData.accountName || "--" }}</div>
        <span v-if="detailData.state == 0" class="state stateClass0">
          {{ detailData.stateText }}
        </span>
        <span v-if="detailData.state == 1" class="state stateClass0">
          {{ detailData.stateText }}
        </span>
        <span v-if="detailData.state == 2" class="state stateClass2">
          {{ detailData.stateText }}
        </span>
        <span v-if="detailData.state == 3" class="state stateClass3">
          {{ detailData.stateText }}
        </span>
        <span v-if="detailData.state == 4" class="state stateClass4">
          {{ detailData.stateText }}
        </span>
        <span v-if="detailData.state == 5" class="state stateClass4">
          {{ detailData.stateText }}
        </span>
        <span v-if="detailData.state == 6" class="state stateClass3">
          {{ detailData.stateText }}
        </span>
      </div>
      <div>
        <!-- 步骤 -->
        <div class="font16 fontC2 mgb30">
          提现金额(元)
          <span class="font24 fnW600">{{ "￥" + config.numFormat(detailData.amount) }}</span>
        </div>
        <div class="step-box">
          <el-steps :active="stepAc">
            <el-step v-for="(item, index) in stepData" :key="index">
              <img
                v-if="stepAc == index"
                slot="icon"
                src="../../assets/icon/wait-icon.png"
                style="width: 15px; height: 15px"
                alt=""
              />
              <div v-else-if="stepAc > index" slot="icon" class="step-spot1"></div>
              <div v-else slot="icon" class="step-spot"></div>
              <div slot="title">
                <div class="a-fs-14">{{ item.stepText }}</div>
                <div v-if="item.stepTime" class="fontC3 font12">
                  {{ item.stepTime }}
                </div>
              </div>
            </el-step>
          </el-steps>
        </div>
        <!-- 提现信息 -->
        <div class="font16 fontC2 mgb16 mgt48">提现信息</div>
        <div class="pdb110 bordrb3">
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">手续费(元)</div>
            <div class="fontC2">
              {{ "￥" + config.numFormat(detailData.totalFee) }}
            </div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">提现渠道</div>
            <div class="fontC2">{{ detailData.payoutMethodText || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">收款银行</div>
            <div class="fontC2">{{ detailData.bankName || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">账号名</div>
            <div class="fontC2">{{ detailData.accountName || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">收款账号</div>
            <div class="fontC2">{{ detailData.accountNumber || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">支行名称</div>
            <div class="fontC2">{{ detailData.bankBranch || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">申请时间</div>
            <div class="fontC2">{{ detailData.createTimeText || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">申请人</div>
            <div class="fontC2">{{ detailData.applyUserName || "--" }}</div>
          </div>
          <div class="flex_start font14 mgb8">
            <div class="w100 fontC4">状态信息</div>
            <div class="fontC2">{{ detailData.stateText || "--" }}</div>
          </div>
        </div>
        <!-- <div class="font16 fontC2 mgb16 mgt48" v-if="detailData.state==1">打款凭证</div>
                <div class="pdb110 bordrb3" v-if="detailData.state==1">
                    <le-upload-file v-model="voucherImage" ref="pic" label="打款凭证" @input="uploadFlie"></le-upload-file>
                </div> -->
        <div v-if="detailData.state == 0 && company.company.id == 2" class="mgt20">
          <el-button type="primary" @click="onSubmit(1)">
            &nbsp;&nbsp;&nbsp;通过&nbsp;&nbsp;&nbsp;
          </el-button>
          <el-button type="danger" @click="dissentShow = true">
            &nbsp;&nbsp;&nbsp;拒绝&nbsp;&nbsp;&nbsp;
          </el-button>
        </div>
        <div v-if="detailData.state == 1" class="mgt20">
          <!-- <el-button type="primary" v-if="this.company.company.id==2" @click="doTradeByOffline">申请线下打款</el-button> -->
          <el-button v-if="company.company.id == 2" type="primary" @click="tradeWithdrawByTl">
            通联付款
          </el-button>
          <el-button @click="closeWithdraw">关闭申请</el-button>
        </div>
      </div>
    </el-card>
    <!-- 提交异议 -->
    <el-dialog title="审核拒绝" :visible.sync="dissentShow" width="30%">
      <div>
        <el-input
          v-model="censorForm.authMsg"
          type="textarea"
          maxlength="100"
          placeholder="请输入审核拒绝原因…"
          :rows="8"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dissentShow = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit(0)">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import config from "../../../src/utils/util";
export default {
  name: "WithdrawalDetail",

  computed: {
    ...mapState(["company"]),
  },
  data() {
    return {
      config: config, //公共方法集
      withdrawId: "",
      stepAc: 0,
      stepData: [], //当前进度
      detailData: {}, //
      dissentShow: false,
      //审核
      censorForm: {
        authResult: "",
        authMsg: "",
        withdrawId: "",
      },
      voucherImage: "", //打款凭证
    };
  },
  created() {
    if (this.$route.query.code) {
      this.withdrawId = this.$route.query.code;
      this.getDetail();
    }
  },
  methods: {
    onSubmit(type) {
      this.censorForm.withdrawId = this.withdrawId;
      if (type == 0) {
        if (!this.censorForm.authMsg) {
          this.$message.error("请输入拒绝原因!");
          return;
        }
        this.censorForm.authResult = false;
      } else if (type == 1) {
        this.censorForm.authResult = true;
      } else {
        this.$message.error("操作错误!");
        return;
      }
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.authWithdraw,
          params: this.censorForm,
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message.success("操作成功");
            setTimeout(() => {
              this.$router.push({
                path: "/withdrawal/withdrawal-view",
              });
            }, 1500);
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    //通联打款
    tradeWithdrawByTl() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.tradeWithdrawByTl,
          params: {
            withdrawId: this.detailData.id,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getDetail();
          } else {
            this.$message({
              type: "error",
              message: res.result.msg,
            });
          }
        });
    },
    //线下打款
    doTradeByOffline() {
      if (!this.voucherImage) {
        this.$message.error("请上传打款凭证!");
        return;
      }
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.doTradeByOffline,
          params: {
            withdrawId: this.detailData.id,
            voucherImage: this.voucherImage,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.getDetail();
          } else {
            this.$message({
              type: "error",
              message: res.result.msg,
            });
          }
        });
    },
    //关闭申请
    closeWithdraw() {
      this.$confirm("是否确认关闭申请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Axios
          ._get({
            url: this.$Config.apiUrl.closeWithdraw,
            params: {
              withdrawId: this.detailData.id,
            },
          })
          .then((res) => {
            if (res.result.code == 0) {
              this.$message({
                type: "success",
                message: "关闭成功!",
              });
              this.getDetail();
            } else {
              this.$message({
                type: "error",
                message: res.result.msg,
              });
            }
          });
      });
    },
    getDetail() {
      this.$Axios
        ._get({
          url: this.$Config.apiUrl.getWithdrawDetail,
          params: {
            withdrawId: this.withdrawId,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            if (res.result.data) {
              const resData = res.result.data;
              const hasIns = resData.stepStatus.findIndex((item) => item.stepStatus == 1);
              if (hasIns != -1) {
                this.stepAc = hasIns;
              } else {
                this.stepAc = resData.stepStatus.length - 1;
              }
              this.stepData = resData.stepStatus;
              this.detailData = resData.withdraw;
            }
          }
        });
    },
    //上传打款凭证
    uploadFlie(val) {
      this.voucherImage = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.step-box {
  padding: 0 114px;
}
.step-spot {
  width: 8px;
  height: 8px;
  background: #adb0b3;
  border-radius: 50%;
}
.step-spot1 {
  width: 8px;
  height: 8px;
  background: #007aff;
  border-radius: 50%;
}
/deep/ .el-step__icon.is-text {
  border: none;
}
.state {
  display: inline-block;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 4px;
  font-weight: normal;
  margin-left: 20px;
}
.stateClass0 {
  background: #e6f1ff;
  color: #007aff;
}
.stateClass2 {
  background: #e6ffe6;
  color: #29cc29;
}
.stateClass3 {
  background: #ebebeb;
  color: #606366;
}
.stateClass4 {
  background: #ffeded;
  color: #ff3b30;
}
</style>
